<template lang="pug">
SliderHistory(:history='historyState')
</template>

<script lang="ts" setup>
/**
 * Define hooks
 */
const { historyState, fetchHistory } = useHistory();

/**
 * Define fetch
 */
await fetchHistory();
</script>
