<template lang="pug">
div(class='Reputation')
	h2(class='text-center short-text')
		span(class='Accent') {{ variablesList.reputationAccentTitle }}
		|
		| {{ variablesList.reputationTitle }}

	div(class='Photo')
		div(class='Image')
			UiImage(:image='reputationState.photo', width='400', lazy)
		div(class='Questions')
			div(v-for='(question, index) in reputationState.questions', :key='index', class='Question') {{ question.text }}

	div(v-if='reputationState.form', class='Form')
		div(class='Title') {{ reputationState.form.header }}
		div(class='Description') {{ reputationState.form.description }}
		UiForm(
			:items='reputationState.form.fields',
			:source='reputationState.form.source',
			:subject='reputationState.form.subject',
			:target='reputationState.form.target',
			agree,
			primary,
			ref='form'
		)
		UiButton(
			theme='filled',
			variant='secondary',
			:size='greaterThan("mobileWide") ? "xl" : "md"',
			rounded,
			wrapped,
			@click='formSubmit'
		) {{ reputationState.button }}
</template>

<script lang="ts" setup>
import { UiForm } from '#components';

/**
 * Define refs
 */
const form = ref<InstanceType<typeof UiForm> | null>(null);

/**
 * Define hooks
 */
const { greaterThan } = usePlatform();
const { variablesList, fetchVariables } = useVariables();
const { reputationState, fetchReputation } = useReputation();

/**
 * Get on form submit
 */
const formSubmit = () => {
	if (form.value) {
		form.value.submitForm();
	}
};

/**
 * Define fetch
 */
await fetchVariables();
await fetchReputation();
</script>

<style lang="less" scoped>
.Reputation {
	.box(grid; 100%; auto; none; 1rem; auto; 100%; center; center);

	& > h2 {
		.grid(1 span);
	}

	& > .Photo {
		max-width: 25rem;
		.grid(1 span);
		.box(grid; 100%; auto; none; 1rem; auto; 100%; center; start);

		& > .Image {
			.grid(1 2; 1 2);
			.grid-aspect(3; 4);
			.relative(1; hidden);
			.box(block; 100%; auto);
			.border-radius(@BorderRadiusSmall);
			&:before {
				content: '';
				.absolute(1; none; 3rem 0 0 0);
				.box(block; 100%; auto; @ColorWhite);
				.border-radius(@BorderRadiusSmall);
			}
			& > img {
				object-fit: cover;
				.absolute(1; none; -18% none none -20%);
				.box(block; 150%);
			}
		}
		& > .Questions {
			padding: 1rem;
			.relative(2);
			.grid(1 2; 1 2; end);
			.box(grid; 100%; auto; none; 0.5rem; auto; 1fr; center; start);
			& > .Question {
				padding: 0.5rem 1rem;
				border: 0.0625rem fade(@ColorBase, 10%) solid;
				.grid(1 span);
				.text(@ColorBase; 1.25em 0.875rem @medium; left; none none);
				.box(block; auto; auto; @ColorWhite);
				.border-radius(@BorderRadiusFull);
			}
		}
	}
	& > .Form {
		max-width: 25rem;
		padding: 1.5rem;
		.grid(1 span);
		.box(grid; 100%; auto; @ColorPrimary; 1rem; auto; auto; center; start);
		.border-radius(@BorderRadiusSmall);

		& > .Title {
			.grid(1 span);
			.text(@ColorWhite; 1.4em 1.25rem @bold; left; uppercase none);
		}
		& > .Description {
			.grid(1 span);
			.text(@ColorWhite; 1.6em 1rem @regular; left; none none);
		}
	}

	@media all and (min-width: @tablet) and (max-width: (@desktop - 1px)) {
		.box(grid; 100%; auto; none; 1rem 3rem; auto; 1fr 1fr; stretch; center);
		& > h2 {
			.grid(1 2; 1 3);
		}
		& > .Photo {
			max-width: 100%;
			.grid(2 3; 1 2);
		}
		& > .Form {
			margin-top: 3rem;
			max-width: 100%;
			.grid(2 3; 2 3);
		}
	}
	@media all and (min-width: @desktop) {
		.box(grid; 100%; auto; none; 2rem 1rem; auto; 1fr 30rem; center; start);
		& > h2 {
			.grid(1 2; 1 3);
		}
		& > .Photo {
			max-width: 100%;
			.grid(2 3; 1 2);
			.box(grid; 100%; auto; none; 1rem; auto; 19rem 1fr; stretch; start);

			& > .Image {
				.grid(1 2; 1 2);
			}
			& > .Questions {
				.grid(1 2; 2 3);
				.box(flex; 100%; 100%; none; flex-start; space-evenly; center; nowrap column);
				& > .Question {
					padding: 1rem;
					&:nth-child(odd) {
						margin-left: 5%;
					}
					&:nth-child(even) {
						margin-left: -4rem;
					}
				}
			}
		}
		& > .Form {
			max-width: 100%;
			.grid(2 3; 2 3);
		}
	}
}
</style>
