import { historyService } from '~/services/history';
import { History } from '~/models/history';

export const useHistoryState = () => ({
	historyState: useState<History.Model[]>('historyState', () => []),
	historyLoaded: useState<boolean>('historyLoaded', () => false),
	historyPending: useState<boolean>('historyPending', () => true),
});

export const useHistory = () => {
	const { historyState, historyLoaded, historyPending } = useHistoryState();

	/**
	 * Fetch history
	 */
	const fetchHistory = async () => {
		if (historyLoaded.value) return;

		historyPending.value = true;

		historyState.value = await historyService.fetch();

		historyLoaded.value = true;
		historyPending.value = false;
	};

	/**
	 * Refresh history
	 */
	const refreshHistory = async () => {
		historyLoaded.value = false;

		await fetchHistory();
	};

	return {
		historyState,
		historyPending,

		fetchHistory,
		refreshHistory,
	};
};
