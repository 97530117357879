import { Common } from '~/models/common';
import { Image } from './image';

export namespace Migration {
	export type Case = {
		title: string;
		accent: string;
		text: string;
	};

	export type Model = Common.SingleModel & {
		title: string;
		subtitle: string;
		description: string;
		cases: Migration.Case[];
		photo: Image.Model[];
	};

	export const modelState: Model = {
		...Common.singleModelState,
		title: '',
		subtitle: '',
		description: '',
		cases: [],
		photo: [],
	};
}
