import type { About } from '~/models/about';

const collection = 'about';

export const aboutService = {
	fetch: async (): Promise<About.Model> => {
		const { getSingletonItem } = useDirectusItems();

		return await getSingletonItem<About.Model>({
			collection,
			params: {
				fields: ['*', 'employees.*', 'employees.photo.*', 'form.*'],
			},
		});
	},
};
