import { Common } from '~/models/common';
import { Image } from '~/models/image';
import { Form } from '~/models/form';

export namespace Reputation {
	export type Question = {
		text: string;
	};

	export type Model = Common.Model & {
		photo: Image.Model;
		questions: Question[];
		button: string;
		form: Form.Model | null;
	};

	export const modelState: Model = {
		...Common.modelState,
		photo: Image.modelState,
		questions: [],
		button: '',
		form: null,
	};
}
