import { Common } from '~/models/common';
import { Image } from '~/models/image';
import { Form } from '~/models/form';

export namespace Manager2 {
	export type Advantage = {
		advantage: string;
	};

	export type Model = Common.SingleModel & {
		photo: Image.Model;
		name: string;
		title: string;
		year: number;
		yearTitle: string;
		header: string;
		text: string;
		button: string;
		form: Form.Model | null;
	};

	export const modelState: Model = {
		...Common.singleModelState,
		photo: Image.modelState,
		name: '',
		title: '',
		year: 1,
		yearTitle: '',
		header: '',
		text: '',
		button: '',
		form: null,
	};
}
