import { manager2Service } from '~/services/manager2';
import { Manager2 } from '~/models/manager2';

export const useManager2State = () => ({
	manager2State: useState<Manager2.Model>('manager2State', () => Manager2.modelState),
	manager2Loaded: useState<boolean>('manager2Loaded', () => false),
	manager2Pending: useState<boolean>('manager2Pending', () => true),
});

export const useManager2 = () => {
	const { manager2State, manager2Loaded, manager2Pending } = useManager2State();

	/**
	 * Fetch manager2
	 */
	const fetchManager2 = async () => {
		if (manager2Loaded.value) return;

		manager2Pending.value = true;

		manager2State.value = await manager2Service.fetch();

		manager2Loaded.value = true;
		manager2Pending.value = false;
	};

	/**
	 * Refresh manager2
	 */
	const refreshManager2 = async () => {
		manager2Loaded.value = false;

		await fetchManager2();
	};

	return {
		manager2State,
		manager2Pending,

		fetchManager2,
		refreshManager2,
	};
};
