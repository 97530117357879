<template lang="pug">
div(class='SliderHistory')
	div(class='SliderHistory--Header')
		h2
			span(class='Accent') {{ variablesList.historyAccentTitle }}
			|
			| {{ variablesList.historyTitle }}
			div(v-if='greaterThan("tablet")') {{ variablesList.historySubtitle }}

	div(v-if='greaterThan("tablet")', class='SliderHistory--CarouselNavigation')
		UiButton(
			class='SliderHistory--CarouselNavigation--Button',
			:class='{ Disabled: activeSlide === 1 }',
			variant='secondary',
			rounded,
			square,
			paddet,
			size='xl',
			iconLeft='system/arrow-left',
			iconLeftSize='md',
			@click='slidePrev'
		)
		UiButton(
			class='SliderHistory--CarouselNavigation--Button',
			:class='{ Disabled: isEndSlide }',
			variant='secondary',
			rounded,
			square,
			size='xl',
			iconRight='system/arrow-right',
			iconRightSize='md',
			@click='slideNext'
		)

	UiCarousel(
		class='SliderHistory--Carousel',
		:perView='greaterThan("desktop") ? 1.1 : greaterThan("tablet") ? 3.3 : greaterThan("mobileWide") ? 2.3 : greaterThan("mobileMedium") ? 1.8 : 1.1',
		ref='slider'
	)
		template(#default='{ carouselItemProps }')
			TransitionGroup(name='slider')
				UiCarouselItem(v-for='history in history', :key='history.id', v-bind='carouselItemProps')
					div(class='SliderHistory--Carousel--Item')
						div(class='History')
							div(class='Title') {{ history.title }}
							div(class='Text', :class='{ FullWidth: history.images.length === 0 }')
								template(v-if='!showMore.includes(history.id)')
									UiProse(:html='history.description', awesome)
									div(class='ShowMore', @click='onShowMore(history.id)') Подробнее
								template(v-else)
									UiProse(:html='history.text', awesome)

							UiImage(v-if='history.images.length', class='Image', :image='history.images[0]', width='500px', lazy)
</template>

<script lang="ts" setup>
import { History } from '~/models/history';
import { UiCarousel } from '#components';

/**
 * Define refs
 */
const slider = ref<InstanceType<typeof UiCarousel> | null>(null);
const activeSlide = computed(() => slider.value?.activeSlide || 1);
const isEndSlide = computed(() => slider.value?.isEndSlide || false);
const slideNext = () => slider.value?.slideNext();
const slidePrev = () => slider.value?.slidePrev();

/**
 * Define props
 */
const { history = [] } = defineProps<{
	history: History.Model[];
}>();

/**
 * Define hooks
 */
const { greaterThan } = usePlatform();
const { variablesList, fetchVariables } = useVariables();

/**
 * Show more
 */
const showMore = ref<number[]>([]);
const onShowMore = (id: number) => showMore.value.push(id);

/**
 * Define fetch
 */
await fetchVariables();
</script>

<style lang="less" scoped>
.SliderHistory {
	.box(grid; 100%; auto; none; 2rem; auto; 85% 1fr; end; start);

	&--Header {
		.grid(1 span, 1 2);
	}

	&--CarouselNavigation {
		.grid(1 span; 2 3; end; end);
		.box(grid; auto; auto; none; 2rem; 1fr; auto; center; center; column);
		&--Button {
			padding: 1rem !important;
			.grid(1 span);

			&.Disabled {
				border-color: transparent;
				background: fade(@ColorBase, 5%);
				.cursor(default);
				.box-shadow(0; 0; 0; 0; transparent) !important;
			}
		}
	}

	&--Carousel {
		.grid(1 span; 1 3);
		.box(grid; 100%; auto; none; 0; 1fr; 100%; start; start);

		&--Item {
			padding: 1.25rem;
			.box(grid; 100%; 100%; fade(@ColorBase, 5%); 0; 1fr; auto; start; start);
			.border-radius(@BorderRadiusSmall);
			& > .History {
				.grid(1 span);
				.box(grid; 100%; auto; none; 1rem 1rem; auto; 1fr; center; start);
				& > .Title {
					.grid(1 2; 1 span);
					.text(@ColorBase; 1.6em 1.25rem @bold; left);
				}
				& > .Text {
					.grid(3 4; 1 span);
					.text(@ColorBase; 1.8em 0.875rem @regular; left);
					&.FullWidth {
						.grid(2 4; 1 span);
					}
					& > .ShowMore {
						margin: 1rem 0 0 0;
						.text(@ColorPrimary);
						.cursor(pointer);
					}
				}
				& > .Image {
					.grid(2 3; 1 span);
					.relative(1; hidden);
					.box(block; 100%; auto);
					.border-radius(@BorderRadiusSmall);
				}
			}

			@media all and (min-width: @desktop) {
				padding: 5rem;
				.border-radius(@BorderRadiusLarge);
				& > .History {
					.box(grid; 100%; auto; none; 1rem 5rem; auto 1fr; 1fr 1fr; center; start);
					& > .Title {
						.grid(1 2; 1 2);
					}
					& > .Text {
						.grid(2 3; 1 2; center);
						.text(@ColorBase; 1.8em 1rem @regular; left);
						&.FullWidth {
							.grid(2 4; 1 3; center);
						}
					}
					& > .Image {
						.grid(1 3; 2 3; start);
						.border-radius(@BorderRadiusMedium);
					}
				}
			}
		}
	}

	@media all and (min-width: @desktop) {
		.box(grid; 100%; auto; none; 5rem; auto; 85% 1fr; end; start);
	}
}
</style>
