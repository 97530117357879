<template lang="pug">
div(class='SliderMigrationCases')
	UiCarousel(
		v-if='lessThan("desktop")',
		class='SliderMigrationCases--Carousel',
		:perView='greaterThan("mobileWide") ? 2.2 : greaterThan("mobileMedium") ? 1.6 : 1.2',
		ref='slider'
	)
		template(#default='{ carouselItemProps }')
			TransitionGroup(name='slider')
				UiCarouselItem(
					v-for='({ title, accent, text }, index) in cases',
					:key='index',
					v-bind='carouselItemProps'
				)
					div(class='SliderMigrationCases--Carousel--Item')
						div(class='Case')
							div(class='Title', v-html='$f.accentedText(title, accent)')
							div(class='Text') {{ text }}

	template(v-else)
		div(v-for='({ title, accent, text }, index) in cases', :key='index', class='Case')
			div(class='Title', v-html='$f.accentedText(title, accent)')
			div(class='Text') {{ text }}
</template>

<script lang="ts" setup>
import { Migration } from '~/models/migration';
import { UiCarousel } from '#components';

/**
 * Define refs
 */
const slider = ref<InstanceType<typeof UiCarousel> | null>(null);

/**
 * Define props
 */
const { cases = [] } = defineProps<{
	cases: Migration.Case[];
}>();

/**
 * Define hooks
 */
const { lessThan, greaterThan } = usePlatform();
</script>

<style lang="less" scoped>
.SliderMigrationCases {
	.box(grid; 100%; auto; none; 2.8125rem; 1fr; auto 1fr auto; stretch; stretch);

	&--Carousel {
		.grid(1 span; 1 4);
		.box(grid; 100%; 100%; none; 1rem; 1fr; 100%; stretch; start);
		&--Item {
			.box(grid; 100%; 100%; none; 1rem; 1fr; auto; stretch; start);
		}
	}

	& .Case {
		padding: 1.875rem;
		border: 0.0625rem solid fade(@ColorBase, 10%);
		.border-radius(@BorderRadiusMedium);
		.box(grid; 100%; 100%; @ColorWhite; 1rem; auto 1fr; 1fr; stretch; start);
		& > .Title {
			.grid(1 span);
			.text(@ColorBase; 1.25em 1.125rem @bold; left);
		}
		& > .Text {
			.grid(1 span);
			.text(@ColorBase; 1.25em 0.875rem @regular; left);
		}
	}

	@media all and (min-width: @laptop) {
		.box(grid; 100%; auto; none; 2.8125rem; 1fr 1fr; 1fr 1fr; stretch; stretch);
	}
	@media all and (min-width: @desktop) {
		& .Case {
			& > .Title {
				.text(@ColorBase; 1.25em 1.5rem @bold; left);
			}
			& > .Text {
				.text(@ColorBase; 1.4em 1.125rem @regular; left);
			}
		}
	}
}
</style>
