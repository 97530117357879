import { Common } from '~/models/common';
import { Image } from './image';
import { Form } from './form';

export namespace Victories {
	export type Model = Common.SingleModel & {
		title: string;
		text: string;
		photo: Image.Model[];
		button: string;
		form: Form.Model | null;
	};

	export const modelState: Model = {
		...Common.singleModelState,
		title: '',
		text: '',
		photo: [],
		button: '',
		form: null,
	};
}
