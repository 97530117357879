<template lang="pug">
div(class='Migration')
	h2(class='super-short-text')
		| {{ migrationState.title }}
		|
		span(class='Accent') {{ migrationState.subtitle }}

	div(class='HeaderDescription Large') {{ migrationState.description }}

	SliderMigrationCases(class='Cases', :cases='migrationState.cases')

	div(class='Slider')
		SliderImages(class='Images', :images='migrationState.photo')
</template>

<script lang="ts" setup>
/**
 * Define hooks
 */
const { migrationState, fetchMigration } = useMigration();

await fetchMigration();
</script>

<style lang="less" scoped>
.Migration {
	.box(grid; 100%; auto; unset; 2rem; auto; 100%; stretch; center);
	.background(none; '@/images/map.png'; no-repeat; bottom center; contain);

	& > h2 {
		.grid(1 span);
	}
	& > .HeaderDescription {
		.grid(1 span);
	}
	& > .Cases {
		.grid(1 span);
	}
	& > .Slider {
		.grid(1 span);
	}

	@media all and (min-width: @tablet) {
		& > h2 {
			text-align: center;
		}
	}

	@media all and (min-width: @laptop) {
		.box(grid; 100%; auto; unset; 2rem 5rem; auto; 1.75fr 1fr; stretch; center);

		& > h2 {
			.grid(1 span; 2 span);
		}
		& > .HeaderDescription {
			.grid(1 span; 2 span);
		}
		& > .Cases {
			.grid(1 span);
		}
		& > .Slider {
			.grid(1 span);
		}
	}
	@media all and (min-width: @desktop) {
		& > .HeaderDescription {
			margin-bottom: 2rem;
		}
	}
}
</style>
