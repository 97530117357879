import { migrationService } from '~/services/migration';
import { Migration } from '~/models/migration';

export const useMigrationState = () => ({
	migrationState: useState<Migration.Model>('migrationState', () => Migration.modelState),
	migrationLoaded: useState<boolean>('migrationLoaded', () => false),
	migrationPending: useState<boolean>('migrationPending', () => true),
});

export const useMigration = () => {
	const { migrationState, migrationLoaded, migrationPending } = useMigrationState();

	/**
	 * Fetch migration
	 */
	const fetchMigration = async () => {
		if (migrationLoaded.value) return;

		migrationPending.value = true;

		migrationState.value = await migrationService.fetch();

		migrationLoaded.value = true;
		migrationPending.value = false;
	};

	/**
	 * Refresh migration
	 */
	const refreshMigration = async () => {
		migrationLoaded.value = false;

		await fetchMigration();
	};

	return {
		migrationState,
		migrationPending,

		fetchMigration,
		refreshMigration,
	};
};
