import { Common } from '~/models/common';
import { History } from '~/models/history';
import { Image } from '~/models/image';

const collection = 'history';

export const historyService = {
	fetch: async (): Promise<History.Model[]> => {
		const { getItems } = useDirectusItems();

		const response = await getItems<
			Omit<History.Model, 'images'> & { images: { directus_files_id: Image.Model }[] }
		>({
			collection,
			params: {
				filter: {
					status: Common.Status.PUBLISHED,
				},
				fields: ['*', 'images.*', 'images.directus_files_id.*'],
			},
		});

		/** Resolve images */
		const resolvedImagesResponse = response.reduce((acc, history) => {
			const images = history.images.reduce((acc, { directus_files_id }) => {
				acc.push(directus_files_id);
				return acc;
			}, [] as Image.Model[]);

			acc.push({ ...history, images });

			return acc;
		}, [] as History.Model[]);

		return resolvedImagesResponse;
	},
};
