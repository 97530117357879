<template lang="pug">
div(class='Manager2')
	h2 {{ variablesList.manager2Title }}

	div(class='Photo')
		div(class='Image')
			UiImage(:image='manager2State.photo', width='800', lazy)
		div(class='Info') 
			div(class='Name') {{ manager2State.name }}
			div(class='Skill', v-html='manager2State.title')
			div(class='Year')
				span {{ manager2State.year }}
				|
				| {{ manager2State.yearTitle }}

	div(class='Content')
		div(class='Title') {{ manager2State.header }}
		div(class='Description', v-html='manager2State.text')
		UiButton(
			theme='filled',
			:size='lessThan("mobileWide") ? "md" : "xl"',
			rounded,
			wrapped,
			@click='getManager2Modal'
		) {{ manager2State.button }}
</template>

<script lang="ts" setup>
/**
 * Define hooks
 */
const { lessThan } = usePlatform();
const { variablesList, fetchVariables } = useVariables();
const { manager2State, fetchManager2 } = useManager2();
const { modalState, modalShow } = useModal();

/**
 * Get manager2 modal
 */
const getManager2Modal = () => {
	if (!manager2State.value.form) return;

	modalState.value = {
		header: manager2State.value.form.header,
		description: manager2State.value.form.description,
		source: manager2State.value.form.source,
		subject: manager2State.value.form.subject,
		form: manager2State.value.form.fields,
		target: manager2State.value.form.target,
	};

	modalShow();
};

/**
 * Define fetch
 */
await fetchVariables();
await fetchManager2();
</script>

<style lang="less" scoped>
.Manager2 {
	.box(grid; 100%; auto; none; 2rem; auto; 1fr; stretch; start);

	& > h2 {
		.grid(1 span);
	}

	& > .Photo {
		.grid(1 span);
		.relative(1; hidden);
		.box(block; 100%; auto);
		& > .Image {
			margin: 0 0 1rem 0;
			.relative(1; hidden);
			.box(flex; 100%; auto; none; flex-start; flex-end; center; nowrap row);
			.border-radius(@BorderRadiusSmall);
			& > img {
				object-fit: cover;
				.box(block; 100%; 100%);
			}
		}
		& > .Info {
			padding: 1rem 2rem;
			.box(grid; 100%; auto; @ColorWhite; 0.5rem; 1fr; auto; center; start);
			.border-radius(@BorderRadiusSmall);
			& > .Name {
				.grid(1 span);
				.text(@ColorBase; 1.25em 1.25rem @bold; left; uppercase none);
			}
			& > .Skill {
				.grid(1 span);
				.text(fade(@ColorBase, 70%); 1.25em 0.875rem @regular; left; none none);
			}
			& > .Year {
				.grid(1 span);
				.box(grid; auto; auto; none; 0.5rem; 1fr; auto 1fr; center; start);
				.text(@ColorBase; 1em 0.875rem @bold; left; none none);
				& > span {
					.grid(1 span);
					.text(@ColorWhite; 1em 1rem @bold);
					.box(flex; 2rem; 2rem; @ColorBase; center; center; center);
					.border-radius(@BorderRadiusFull);
				}
			}
		}
	}
	& > .Content {
		padding: 1rem;
		.grid(1 span);
		.box(grid; 100%; auto; @ColorWhite; 0.5rem; auto; 1fr; center; stretch);
		.border-radius(@BorderRadiusSmall);
		& > .Title {
			.grid(1 span);
			.text(@ColorPrimary; 1.4em 1.125rem @bold; left);
		}
		& > .Description {
			.grid(1 span);
			.text(@ColorBase; 1.4em 0.875rem @regular; left);
		}
	}
	@media all and (min-width: @tablet-small) {
		& > .Photo {
			margin: 0;
			.relative(1; hidden);
			& > .Image {
				.relative(1; hidden);
				.border-radius(@BorderRadiusMedium);
				& > img {
					.box(block; 140%; 100%);
				}
			}
			& > .Info {
				max-width: 19rem;
				.absolute(2; none; 2rem 2rem);
				.box(grid; auto; auto; fade(@ColorWhite, 70%); 1rem; 1fr; auto; center; start);
				& > .Name {
					.text(@ColorBase; 1.25em 2rem @bold; left; unset none);
				}
			}
		}
		& > .Content {
			padding: 5rem;
			.border-radius(@BorderRadiusMedium);
			.box(grid; 100%; auto; @ColorWhite; 1rem; auto; 1fr; center; stretch);
			& > .Title {
				.text(@ColorPrimary; 1.4em 1.5rem @bold; left);
			}
			& > .Description {
				.text(@ColorBase; 1.4em 1rem @regular; left);
			}
		}
	}

	@media all and (min-width: @desktop) {
		.box(grid; 100%; auto; none; 2rem; auto; 1fr 1fr; stretch; start);
		& > h2 {
			.grid(1 span; 1 3);
		}
	}
}
</style>
