import { Common } from '~/models/common';
import { Form } from '~/models/form';
import { Employee } from './employee';

export namespace About {
	export type Model = Common.SingleModel & {
		title: string;
		text: string;
		button: string;
		employees: Employee.Model[];
		form: Form.Model | null;
	};

	export const modelState: Model = {
		...Common.singleModelState,
		title: '',
		text: '',
		button: '',
		employees: [],
		form: Form.modelState,
	};
}
