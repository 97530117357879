import { Migration } from '~/models/migration';
import { Image } from '~/models/image';

const collection = 'migration';

export const migrationService = {
	fetch: async (): Promise<Migration.Model> => {
		const { getSingletonItem } = useDirectusItems();

		const response = await getSingletonItem<
			Omit<Migration.Model, 'photo'> & { photo: { directus_files_id: Image.Model }[] }
		>({
			collection,
			params: {
				fields: ['*', 'photo.*', 'photo.directus_files_id.*'],
			},
		});

		/** Resolve images */
		const images = response.photo.reduce((acc, { directus_files_id }) => {
			acc.push(directus_files_id);
			return acc;
		}, [] as Image.Model[]);

		return { ...response, photo: images };
	},
};
