import { victoriesService } from '~/services/victories';
import { Victories } from '~/models/victories';

export const useVictoriesState = () => ({
	victoriesState: useState<Victories.Model>('victoriesState', () => Victories.modelState),
	victoriesLoaded: useState<boolean>('victoriesLoaded', () => false),
	victoriesPending: useState<boolean>('victoriesPending', () => true),
});

export const useVictories = () => {
	const { victoriesState, victoriesLoaded, victoriesPending } = useVictoriesState();

	/**
	 * Fetch victories
	 */
	const fetchVictories = async () => {
		if (victoriesLoaded.value) return;

		victoriesPending.value = true;

		victoriesState.value = await victoriesService.fetch();

		victoriesLoaded.value = true;
		victoriesPending.value = false;
	};

	/**
	 * Refresh victories
	 */
	const refreshVictories = async () => {
		victoriesLoaded.value = false;

		await fetchVictories();
	};

	return {
		victoriesState,
		victoriesPending,

		fetchVictories,
		refreshVictories,
	};
};
