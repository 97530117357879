<template lang="pug">
div(class='Victories')
	div(class='Info')
		h2 {{ victoriesState.title }}
		SliderCases(v-if='lessThan("desktop")', :cases='victoriesState.photo')
		div(class='Description', v-html='victoriesState.text')
		UiButton(theme='filled', size='xl', rounded, wrapped, @click='getCasesModal') {{ victoriesState.button }}
	div(v-if='greaterThan("tablet")', class='Items')
		template(v-for='(item, index) in victoriesState.photo', :key='item.id')
			div(v-if='index < 3', class='Item')
				UiImage(:image='item', width='400px', lazy)
</template>

<script lang="ts" setup>
/**
 * Define hooks
 */
const { lessThan, greaterThan } = usePlatform();
const { victoriesState, fetchVictories } = useVictories();
const { modalState, modalShow } = useModal();

/**
 * Get victories modal
 */
const getCasesModal = () => {
	if (!victoriesState.value.form) return;

	modalState.value = {
		header: victoriesState.value.form.header,
		description: victoriesState.value.form.description,
		source: victoriesState.value.form.source,
		subject: victoriesState.value.form.subject,
		form: victoriesState.value.form.fields,
		target: victoriesState.value.form.target,
	};

	modalShow();
};

/**
 * Define fetch
 */
await fetchVictories();
</script>

<style lang="less" scoped>
.Victories {
	.box(grid; 100%; auto; none; 3rem; auto; 1fr; center; center);
	& > .Info {
		.grid(1 span);
		.box(grid; 100%; auto; none; 1rem; auto; 1fr; center; start);
		& > .Description {
			margin: -1rem 0 0 0;
			.box(block; auto; auto; none);
			.text(@ColorBase; 1.6em 0.875rem @regular; left);
		}
	}
	& > .Items {
		.grid(1 span);
		.box(grid; 100%; auto; none; 1.25rem; repeat(2, 1fr); repeat(2, 1fr); stretch; stretch);
		& > .Item {
			.relative(1; hidden);
			.grid(1 span);

			& > img {
				object-fit: cover;
				.box(block; 100%; 100%);
				.border-radius(@BorderRadiusSmall);
			}

			&:nth-child(1) {
				.grid-aspect(2.65; 2);
				.grid(1 2; 1 2);
			}
			&:nth-child(2) {
				.grid-aspect(2.65; 2);
				.grid(2 3; 1 2);
			}
			&:nth-child(3) {
				.grid(1 3; 2 3);
			}
		}
	}

	@media all and (min-width: @mobile) {
		& > .Info {
			& > .Description {
				.text(@ColorBase; 1.8em 1rem @regular; left);
			}
		}
	}

	@media all and (min-width: @laptop) {
		.box(grid; 100%; auto; none; 3rem; auto; 1fr 1fr; center; center);

		& > .Info {
			.box(grid; 100%; auto; none; 2rem; auto; 1fr; center; start);
			& > .Description {
				margin: 0;
				max-width: 35rem;
				.box(block; auto; auto; none);
			}
		}
	}
}
</style>
