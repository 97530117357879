import { Manager2 } from '~/models/manager2';

const collection = 'manager2';

export const manager2Service = {
	fetch: async (): Promise<Manager2.Model> => {
		const { getSingletonItem } = useDirectusItems();

		return await getSingletonItem<Manager2.Model>({
			collection,
			params: {
				fields: ['*', 'photo.*', 'form.*'],
			},
		});
	},
};
