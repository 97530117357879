<template lang="pug">
div(class='AboutUnit')
	div(class='AboutUnit--Employees')
		SliderAboutEmployees(:employees='aboutState.employees')

	div(class='AboutUnit--Info')
		h2(v-if='greaterThan("tablet")') {{ aboutState.title }}
		UiProse(class='Text', :html='aboutState.text', awesome)
		UiButton(
			variant='primary',
			theme='filled',
			:size='greaterThan("mobileWide") ? "xl" : "md"',
			rounded,
			@click='getAboutFormModal'
		) {{ aboutState.button }}
</template>

<script lang="ts" setup>
/**
 * Define hooks
 */
const { greaterThan } = usePlatform();
const { aboutState, fetchAbout } = useAbout();
const { modalState, modalShow } = useModal();

/**
 * Define fetch
 */
await fetchAbout();

/**
 * Get about form modal
 */
const getAboutFormModal = () => {
	if (!aboutState.value.form) return;

	modalState.value = {
		header: aboutState.value.form.header,
		description: aboutState.value.form.description,
		source: aboutState.value.form.source,
		subject: aboutState.value.form.subject,
		form: aboutState.value.form.fields,
		target: aboutState.value.form.target,
	};

	modalShow();
};
</script>

<style lang="less" scoped>
.AboutUnit {
	.box(grid; 100%; auto; none; 2.5rem; auto; 100%; center; start);

	&--Employees {
		.grid(1 span);
		.box(grid; 100%; auto; none; 0; auto; 100%; start; start);
	}
	&--Info {
		.grid(1 span);
		.box(grid; 100%; auto; none; 0.5rem; auto; auto; start; start);
		& > h2 {
			margin: 0 0 1rem 0;
		}
		& > .Text {
			.text(@ColorBase; 1.6em 0.875rem @regular; left; none none);
		}
	}

	@media all and (min-width: @tablet-small) and (max-width: (@laptop - 1px)) {
		&--Info {
			& > .Text {
				.text(@ColorBase; 1.6em 1rem);
			}
		}
	}
	@media all and (min-width: @laptop) and (max-width: (@desktop - 1px)) {
		&--Info {
			& > .Text {
				.text(@ColorBase; 1.6em 1.125rem);
			}
		}
	}
	@media all and (min-width: @desktop) {
		.box(grid; 100%; auto; none; 6rem; auto; 1.25fr 1fr; stretch; start);

		&--Employees {
			overflow: hidden;
		}
		&--Info {
			padding-bottom: 1rem;
			.box(grid; 100%; auto; none; 1rem; auto 1fr auto; auto; start; start);
			& > h2 {
				margin: 0 0 1rem 0;
			}
			& > .Text {
				.text(@ColorBase; 1.6em 1.25rem);
			}
		}
	}
}
</style>
