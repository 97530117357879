import { aboutService } from '~/services/about';
import { About } from '~/models/about';

export const useAboutState = () => ({
	aboutState: useState<About.Model>('aboutState', () => About.modelState),
	aboutLoaded: useState<boolean>('aboutLoaded', () => false),
	aboutPending: useState<boolean>('aboutPending', () => true),
});

export const useAbout = () => {
	const { aboutState, aboutLoaded, aboutPending } = useAboutState();

	/**
	 * Fetch about
	 */
	const fetchAbout = async () => {
		if (aboutLoaded.value) return;

		aboutPending.value = true;

		aboutState.value = await aboutService.fetch();

		aboutLoaded.value = true;
		aboutPending.value = false;
	};

	/**
	 * Refresh about
	 */
	const refreshAbout = async () => {
		aboutLoaded.value = false;

		await fetchAbout();
	};

	return {
		aboutState,
		aboutPending,

		fetchAbout,
		refreshAbout,
	};
};
