<template lang="pug">
div(class='Practice')
	h2 {{ variablesList.practiceTitle }}
	div(class='Link')
		UiButton(
			:to='getPageUrlByType("arbitrage")',
			size='md',
			variant='secondary',
			iconRight='system/arrow-top-right',
			iconRightSize='sm',
			rounded
		) Посмотреть все

	SliderPractice(class='SliderPractice', :practice='practiceState')
</template>

<script lang="ts" setup>
/**
 * Define hooks
 */
const { variablesList, fetchVariables } = useVariables();
const { practiceState, fetchPractice } = usePractice(true);
const { getPageUrlByType, fetchResolver } = useResolver();

/**
 * Define fetch
 */
await fetchVariables();
await fetchPractice();
await fetchResolver();
</script>

<style lang="less" scoped>
.Practice {
	.box(grid; 100%; auto; none; 1.5rem; auto; 100%; center; start);

	& > h2 {
		.grid(1 span);
	}
	& > .Link {
		.grid(3 4; 1 span);
	}
	& > .SliderPractice {
		.grid(2 3; 1 span);
	}

	@media all and (min-width: @tablet-small) {
		.box(grid; 100%; auto; none; 1.5rem; auto; 60% 1fr; center; start);
		& > .Link {
			.grid(1 2; 2 3; center; end);
		}
		& > .SliderPractice {
			.grid(2 3; 1 3);
		}
	}
}
</style>
