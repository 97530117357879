import { Reputation } from '~/models/reputation';

const collection = 'reputation';

export const reputationService = {
	fetch: async (): Promise<Reputation.Model> => {
		const { getSingletonItem } = useDirectusItems();

		return await getSingletonItem<Reputation.Model>({
			collection,
			params: {
				fields: ['*', 'photo.*', 'form.*'],
			},
		});
	},
};
