import { reputationService } from '~/services/reputation';
import { Reputation } from '~/models/reputation';

export const useReputationState = () => ({
	reputationState: useState<Reputation.Model>('reputationState', () => Reputation.modelState),
	reputationLoaded: useState<boolean>('reputationLoaded', () => false),
	reputationPending: useState<boolean>('reputationPending', () => true),
});

export const useReputation = () => {
	const { reputationState, reputationLoaded, reputationPending } = useReputationState();

	/**
	 * Fetch reputation
	 */
	const fetchReputation = async () => {
		if (reputationLoaded.value) return;

		reputationPending.value = true;

		reputationState.value = await reputationService.fetch();

		reputationLoaded.value = true;
		reputationPending.value = false;
	};

	/**
	 * Refresh reputation
	 */
	const refreshReputation = async () => {
		reputationLoaded.value = false;

		await fetchReputation();
	};

	return {
		reputationState,
		reputationPending,

		fetchReputation,
		refreshReputation,
	};
};
